<template>
  <div>
    <el-form :inline="true" :model="form" ref="ruleForm" label-width="100px">
      <el-form-item class="form-item" label="订单号" prop="orderid">
        <el-input v-model="form.orderid" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="用户ID" prop="student_id">
        <el-input
          v-model="form.student_id"
          placeholder="请输入用户ID"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="用户手机" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="支付方式" prop="payway">
        <el-select v-model="form.payway" placeholder="全部">
          <el-option label="微信" value="0"></el-option>
          <el-option label="支付宝" value="1"></el-option>
          <el-option label="苹果" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="审核状态" prop="tk_audit_status">
        <el-select v-model="form.tk_audit_status" placeholder="全部">
          <el-option label="待审核" value="1"></el-option>
          <el-option label="审核通过" value="2"></el-option>
          <el-option label="已驳回" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="退款状态" prop="tk_status">
        <el-select v-model="form.tk_status" placeholder="全部">
          <el-option label="待退款" value="1"></el-option>
          <el-option label="已退款" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-content">
      <div class="table-title">退款订单列表</div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column fixed prop="orderid" label="退款单号" width="180">
        </el-table-column>
        <el-table-column prop="orderid" label="订单号" width="180">
        </el-table-column>
        <el-table-column
          prop="type"
          label="课程类型"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="goods" label="课程名称" width="200">
        </el-table-column>
        <el-table-column prop="theme" label="主题" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="levelid"
          label="所属级别"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="student_id" label="用户ID" width="180">
        </el-table-column>
        <el-table-column
          prop="username"
          label="用户昵称"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="paytime"
          label="支付时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payway"
          label="支付来源"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="订单状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="finish_class_status"
          label="完课状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="price"
          label="实付金额"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tk_audit_status"
          label="审核状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tk_status"
          label="退款状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template #default="scope">
            <el-button
              v-if="scope.row.tk_audit_status === '待审核'"
              @click="handleClick(scope.$index)"
              type="text"
              size="small"
              >审核</el-button
            >
            <el-button
              v-else-if="
                scope.row.tk_audit_status === '审核通过' &&
                scope.row.tk_status === '退款成功'
              "
              @click="handleClick(scope.$index)"
              type="text"
              size="small"
              >查看备注</el-button
            >
            <el-button
              v-else-if="scope.row.tk_audit_status === '已驳回'"
              @click="handleClick(scope.$index)"
              type="text"
              size="small"
              >查看驳回理由</el-button
            >
            <el-button
              v-else-if="
                scope.row.tk_audit_status === '审核通过' &&
                scope.row.tk_status === '待退款'
              "
              @click="handRefund(scope.row.id)"
              type="text"
              size="small"
              >退款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
    </div>
    <el-drawer
      title="退款审核"
      v-model="drawer"
      direction="rtl"
      destroy-on-close
      @close="handClose"
    >
      <div class="refund-drawer-content">
        <el-scrollbar :height="h">
          <div class="refund-drawer">
            <el-card class="box-card">
              <template #header>
                <div class="card-header">
                  <span>用户信息</span>
                </div>
              </template>
              <el-form label-width="100px">
                <el-form-item label="用户昵称：">{{
                  refundData.username
                }}</el-form-item>
                <el-form-item label="用户手机：">{{
                  refundData.phone
                }}</el-form-item>
                <el-form-item label="完课状态：">{{
                  refundData.finish_class_status
                }}</el-form-item>
              </el-form>
            </el-card>
            <el-card class="box-card">
              <template #header>
                <div class="card-header">
                  <span>订单信息</span>
                </div>
              </template>
              <el-form label-width="100px">
                <el-form-item label="订单号：">{{
                  refundData.orderid
                }}</el-form-item>
                <el-form-item label="支付金额：">{{
                  refundData.price
                }}</el-form-item>
                <el-form-item label="支付来源：">{{
                  refundData.payway
                }}</el-form-item>
                <el-form-item label="支付时间：">{{
                  refundData.paytime
                }}</el-form-item>
              </el-form>
            </el-card>
            <el-card class="box-card">
              <template #header>
                <div class="card-header">
                  <span>课程信息</span>
                </div>
              </template>
              <el-form label-width="100px">
                <el-form-item label="课程类型：">{{
                  refundData.type
                }}</el-form-item>
                <el-form-item label="所属级别：">{{
                  refundData.levelid
                }}</el-form-item>
                <el-form-item label="主题：">{{
                  refundData.theme
                }}</el-form-item>
              </el-form>
            </el-card>
          </div>
          <div class="refund-drawer">
            <el-form
              v-if="isRefund"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
            >
              <el-form-item label="退款审核：" prop="tk_audit_status">
                <el-radio-group
                  v-model="ruleForm.tk_audit_status"
                  @change="handChangeRefund"
                >
                  <el-radio label="2">通过</el-radio>
                  <el-radio label="3">驳回</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                v-if="isShow"
                :label="labelText"
                prop="tk_audit_remarks"
              >
                <el-input
                  type="textarea"
                  v-model="ruleForm.tk_audit_remarks"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >确 认</el-button
                >
              </el-form-item>
            </el-form>
            <el-form v-else label-width="100px">
              <el-form-item label="退款审核：">{{
                refundData.tk_audit_status
              }}</el-form-item>
              <el-form-item :label="labelText">{{
                refundData.tk_audit_remarks
              }}</el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handClose">关 闭</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-scrollbar>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  getRefundList,
  getRefundInfo,
  auditRefund,
  orderRefund,
} from "@/api/apiList/order-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        orderid: "",
        student_id: "",
        phone: "",
        payway: "",
        tk_audit_status: "",
        tk_status: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 10,
      loading: true,
      drawer: false,
      h: 0,
      refundData: {},
      ruleForm: {
        tk_audit_status: "",
        tk_audit_remarks: "",
        id: "",
      },
      rules: {
        tk_audit_status: [
          { required: true, message: "请选择审核状态", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写内容", trigger: "blur" }],
      },
      isShow: false,
      labelText: "",
      isRefund: true,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.handList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClick(e) {
      let obj = {
        id: this.tableData[e].id,
      };
      getRefundInfo(obj).then((res) => {
        if (res.result === "200") {
          switch (res.data.type) {
            case 1:
              res.data.type = "主课";
              break;
            case 2:
              res.data.type = "小课包";
              break;
          }
          switch (res.data.payway) {
            case 0:
              res.data.payway = "微信支付";
              break;
            case 1:
              res.data.payway = "支付宝支付";
              break;
            case 2:
              res.data.payway = "苹果内购";
              break;
          }
          switch (res.data.levelid) {
            case 1:
              res.data.levelid = "U1-L1";
              break;
            case 2:
              res.data.levelid = "U2-L4";
              break;
          }
          switch (res.data.tk_audit_status) {
            case 1:
              res.data.tk_audit_status = "待审核";
              break;
            case 2:
              res.data.tk_audit_status = "审核通过";
              break;
            case 3:
              res.data.tk_audit_status = "已驳回";
              break;
          }
          switch (res.data.tk_status) {
            case 1:
              res.data.tk_status = "待退款";
              break;
            case 2:
              res.data.tk_status = "退款成功";
              break;
            case 3:
              res.data.tk_status = "退款失败";
              break;
          }
          switch (res.data.finish_class_status) {
            case 1:
              res.data.finish_class_status = "未完课";
              break;
            case 2:
              res.data.finish_class_status = "已完课";
              break;
          }
          this.refundData = res.data;
          this.ruleForm.id = this.tableData[e].id;
          if (this.tableData[e].tk_audit_status === "待审核") {
            this.isRefund = true;
          } else {
            if (this.tableData[e].tk_status === "退款成功") {
              this.labelText = "备注信息：";
            } else {
              this.labelText = "驳回理由：";
            }
            this.isRefund = false;
          }
          this.drawer = true;
        }
      });
    },
    handRefund(e) {
      this.$confirm("请仔细核对信息，提交后不能修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let obj = {
          id: e,
        };
        orderRefund(obj).then((res) => {
          if (res.result === "200") {
            this.loading = true;
            this.$message.success({
              message: res.data.info,
              type: "success",
            });
            this.handList();
          }
        });
      });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    handClose() {
      this.drawer = false;
      this.ruleForm = {
        tk_audit_status: "",
        tk_audit_remarks: "",
        id: "",
      };
    },
    handChangeRefund(e) {
      this.isShow = true;
      if (e === "2") {
        this.labelText = "备注信息：";
      } else {
        this.labelText = "驳回理由：";
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          auditRefund(this.ruleForm).then((res) => {
            if (res.result === "200") {
              this.handList();
              this.drawer = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    handList() {
      getRefundList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.type) {
              case 1:
                item.type = "主课";
                break;
              case 2:
                item.type = "小课包";
                break;
            }
            switch (item.status) {
              case 0:
                item.status = "待支付";
                break;
              case 1:
                item.status = "已支付";
                break;
              case 2:
                item.status = "已发货";
                break;
            }
            switch (item.payway) {
              case 0:
                item.payway = "微信支付";
                break;
              case 1:
                item.payway = "支付宝支付";
                break;
              case 2:
                item.payway = "苹果内购";
                break;
            }
            switch (item.levelid) {
              case 1:
                item.levelid = "U1-L1";
                break;
              case 2:
                item.levelid = "U2-L4";
                break;
            }
            switch (item.tk_audit_status) {
              case 1:
                item.tk_audit_status = "待审核";
                break;
              case 2:
                item.tk_audit_status = "审核通过";
                break;
              case 3:
                item.tk_audit_status = "已驳回";
                break;
            }
            switch (item.tk_status) {
              case 1:
                item.tk_status = "待退款";
                break;
              case 2:
                item.tk_status = "退款成功";
                break;
              case 3:
                item.tk_status = "退款失败";
                break;
            }
            switch (item.finish_class_status) {
              case 1:
                item.finish_class_status = "未完课";
                break;
              case 2:
                item.finish_class_status = "已完课";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.h = document.documentElement.clientHeight - 78 + "px";
    this.handList();
  },
};
</script>

<style lang="scss">
.refund-drawer-content {
  border-top: 1px solid #ccc;

  .refund-drawer {
    padding: 30px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #ccc;
  }

  .box-card {
    margin-bottom: 20px;
  }
}
</style>
